import Vue from 'vue'
import App from './App.vue'
import Particles from "particles.vue";
import VueFormulate from '@braid/vue-formulate';
import VueFullPage from 'vue-fullpage.js'

Vue.use(VueFormulate);
Vue.use(Particles);
Vue.use(VueFullPage);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
