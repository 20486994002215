<template>
    <div v-show="active">
        <ul>
            <li @click="$parent.goto('performance')" :style="styleObj">Performance</li>
            <li @click="$parent.goto('sniper')" :style="styleObj">SniperOffer</li>
            <li @click="$parent.goto('canal1')" :style="styleObj">Telecom</li>
            <li @click="$parent.goto('aws1')" :style="styleObj">Segurança</li>
            <li @click="$parent.goto('contato')" :style="styleObj">Contato</li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'MenuItems',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
    isTelecom: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    styleObj() {
      if (this.isTelecom) {
        return {
          color: '#000',
        };
      } else {
        return {
          color: '#fff',
        };
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
    display: block;
    list-style-type: none; 
    color: #fff;
    li {
        margin: 0 10px;
        font-size: 16px;
        font-family: $museo500;
        line-height: 30px;
        cursor: pointer;
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}
@media only screen and (max-width: 1030px) {
    ul {
        display: block;
    }
}
</style>