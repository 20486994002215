<template>
    <header :style="styleObj">
        
        <img v-if="!this.isTelecom" src="~@/assets/images/oston-white.svg" alt="Oi Logo">
        <img v-else src="~@/assets/images/oston-black.svg" alt="Oi Logo">
        
        <!-- <MenuItems class="menu-home" :isTelecom="isTelecom"/> -->
        <BurgerMenu :active="isBurgerActive" :isTelecom="isTelecom"/>

    </header>
</template>

<script>
import BurgerMenu from "./BurgerMenu";

export default {
    name: 'Header',
    data: () => ({
      isBurgerActive: false
    }),
    props: {
      isTelecom: {
        type: Boolean,
        required: true,
        default: false
      }
    },
    components: {
      BurgerMenu,
    },
    methods: {
        toggle() {
          this.isBurgerActive = !this.isBurgerActive
        },
        goto(ref) {
          this.$parent.goto(ref);
        }
    },
    computed: {
    styleObj() {
      if (this.isTelecom) {
        return {
          background: '#F5F5F6',
        };
      } else {
        return {
          background: '#000',
        };
      }
    }
  }
}
</script>

<style lang="scss" scoped>
header {
    position: fixed;
    width: 100%;
    display: flex;
    padding: 25px 70px;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 3;
    img {
      width: 106px;
      height: 27px;
    }
}

// @media only screen and (max-width: 1030px) {
//     header {
//         .menu-home {
//             display: none;
//         }
//     }
// }
/* Portrait iPhone 6 */
@media only screen 
  and (min-device-width: 280px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
      header {
        padding: 25px 35px;
      }
  }
</style>
