import axios from 'axios';

import Header from '../../components/Header.vue';
import Footer from '../../components/Footer.vue';

import ScrollPerformance from "../../components/ScrollPerformance/index.vue";

import "pathseg";

export default {
    name: "Home",
    components: {
      Header,
      Footer,
      ScrollPerformance,
    },
    data() {
      return {
        formValues: {},
        selectedFile: null,
        scrollPosition: null,
        isTelecom: false,
        successForm: false,
        errorForm: false,
        loadingForm: false,
        loadOfertas: false,
        loadVantagens: false,
        options: {
          licenseKey: 'EDED8609-9F014847-926328C4-4D2BBBC1',
          menu: '#menu',
          anchors: ['performance', 'sniper', 'canal1', 'canal2', 'canal3', 'aws1', 'contato'],
          scrollBar: true,
          navigation: true,
          responsiveWidth: '767'
        },
      }
    },
    methods: {
      onLoadVantagens() {
        this.loadVantagens = true;
      },
      onLoadOfertas() {
        this.loadOfertas = true;
      },
      change(event) {
        this.selectedFile = event.target.files[0];
      },
      handleSubmit() {
        const nameAndEmail = "Nome: " + this.formValues.nome + "\n" + "E-mail: " + this.formValues.email
        this.loadingForm = true;
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('filetype', '.pdf');
        formData.append('filename', 'currículo');
        formData.append('channels', 'site-oston');
        formData.append('initial_comment', nameAndEmail);

        const config = {
          headers: {
            Authorization: "Bearer xoxp-383201358513-788220909907-1803139015621-1658493260731039f82867ac3018ac97" 
          }
        };
          
        const data = formData;

        axios.post('https://api.oston.io/site/resume', data, config)
        .then(({data}) => {
          if (data.ok) {
            this.successForm = true;
            this.errorForm = false;
            this.loadingForm = false;
          } else {
            this.errorForm = true;
            this.successForm = false;
            this.loadingForm = false;
          }
        })
        .catch((err) => {
          this.errorForm = true;
          this.successForm = false;
          this.loadingForm = false;
        });
      },
      goto(refName) {
        fullpage_api.moveTo(refName, 0);
      },
      updateScroll() {
        this.scrollPosition = window.scrollY;
        if ((this.scrollPosition + 31) >= (this.$refs.telecom && this.$refs.telecom.offsetParent.offsetTop) && (this.scrollPosition + 31) <= (this.$refs.seguranca && this.$refs.seguranca.offsetParent.offsetTop)) {
          this.isTelecom = true;
        } else {
          this.isTelecom = false;
        }

      }
    },
    mounted() {
      window.addEventListener('scroll', this.updateScroll);
    }
}