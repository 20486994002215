<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
            >
                <header class="modal-header">
                    <slot name="header">
                        <img src="@/assets/images/logo-modal.png" alt="Logo Oston">

                        <h6> 
                            Última atualização <br />
                            Sex 7 de Maio de 2021
                        </h6>

                        <button
                        type="button"
                        class="btn-close"
                        @click="$parent.closeModal"
                        >
                            x
                        </button>
                    </slot>
                </header>
                <section class="modal-body">
                    <slot name="body" v-once>
                        <div>
                            <h3>O QUE SÃO COOKIES?</h3>
                            <p>
                                Cookies são pequenos arquivos que transferimos para o seu 
                                navegador ou dispositivo (como celular ou tablet) que nos 
                                permitem reconhecer o seu navegador ou dispositivo, e saber 
                                como e quando as páginas e os Produtos e Serviços da Oston 
                                são visitados, bem como quantas pessoas acessam o Site da Oston. 
                                Eles podem ser úteis para, por exemplo, fazermos o Site se encaixar 
                                na sua tela, entender melhor as suas preferências e lhe oferecer um Serviço 
                                mais eficiente. Os cookies geralmente também têm uma data de expiração. 
                                Por exemplo, alguns cookies são excluídos automaticamente quando você fecha 
                                o navegador (os chamados cookies de sessão), enquanto outros podem ser 
                                armazenados por mais tempo no computador até serem excluídos 
                                manualmente (os chamados cookies persistentes).
                            </p>
                        </div>
                        
                        <div>
                            <h3>QUAIS COOKIES SÃO UTILIZADOS EM NOSSO SITE?</h3>
                            <p>
                                A Oston, desenvolvedora e administradora de diversos Produtos, 
                                utiliza cookies estritamente necessários, de análise, de funcionalidade 
                                e de marketing quando você interage com nossos websites. Importante 
                                esclarecer que a Oston não se responsabiliza pelo uso de cookies por 
                                terceiros. Fique atento, pois os cookies colocados por terceiros podem 
                                eventualmente continuar a monitorar as suas atividades online mesmo depois 
                                de ter saído de nossos Produtos, sendo recomendável que você limpe seu 
                                histórico de navegação regularmente. Abaixo você encontrará uma lista dos 
                                cookies que utilizamos, com suas respectivas finalidades.
                            </p>
                        </div>
                        
                        <div>
                            <h3>COOKIES NECESSÁRIOS</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cookies</th>
                                        <th>Nome</th>
                                        <th>Domínio</th>
                                        <th>Data de Expiração</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="9">GOOGLE</td>
                                    </tr>
                                    <tr>
                                        <td>1P_JAR</td>
                                        <td>.google.com</td>
                                        <td>1 mês</td>
                                    </tr>
                                    <tr>
                                        <td>SID</td>
                                        <td>.youtube.com /
                                            .google.com</td>
                                        <td>2 anos</td>
                                    </tr>
                                    <tr>
                                        <td>HSID</td>
                                        <td>.youtube.com /
                                            .google.com</td>
                                        <td>2 anos</td>
                                    </tr>
                                    <tr>
                                        <td>SSID</td>
                                        <td>.youtube.com /
                                            .google.com</td>
                                        <td>2 anos</td>
                                    </tr>
                                    <tr>
                                        <td>NID</td>
                                        <td>.google.com</td>
                                        <td>6 meses</td>
                                    </tr>
                                    <tr>
                                        <td>APISID</td>
                                        <td>.youtube.com /
                                            .google.com</td>
                                        <td>2 anos</td>
                                    </tr>
                                    <tr>
                                        <td>SAPISID</td>
                                        <td>.youtube.com /
                                            .google.com</td>
                                        <td>2 anos</td>
                                    </tr>
                                    <tr>
                                        <td>SIDCC</td>
                                        <td>.google.com</td>
                                        <td>3 meses</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div>
                            <p>
                                <strong>
                                    1P_JAR: 
                                </strong>
                                Este cookie contém informações sobre como o usuário 
                                final usa o site e qualquer publicidade que o usuário 
                                final possa ter visto antes de visitar o referido site.
                            </p>

                            <p>
                                <strong>
                                    SID: 
                                </strong>
                                Usado para gerenciamento de estado de sessão.
                            </p>

                            <p>
                                <strong>
                                    HSID:
                                </strong>
                                Este cookie é definido pela DoubleClick (que é 
                                propriedade do Google) para construir um perfil dos 
                                interesses do visitante do site e mostrar anúncios 
                                relevantes em outros sites.
                            </p>

                            <p>
                                <strong>
                                    SSID / SIDCC / 1P_JAR: 
                                </strong>
                                Este cookie contém informações sobre como o usuário 
                                final usa o site e qualquer publicidade que o usuário
                                final possa ter visto antes de visitar o referido site.
                            </p>

                            <p>
                                <strong>
                                    NID: 
                                </strong>
                                usa os dados coletados na maioria desses serviços 
                                para traçar o perfil dos interesses dos usuários da 
                                web e vender espaço publicitário para organizações 
                                com base nesses perfis de interesse, bem como alinhar 
                                os anúncios ao conteúdo das páginas onde os anúncios 
                                de seus clientes aparecem.
                            </p>

                            <p>
                                <strong>
                                    APISID / SAPISID: 
                                </strong>
                                Esse cookie da DoubleClick é geralmente definido por 
                                meio do site por parceiros de publicidade e usado 
                                por eles para construir um perfil dos interesses do 
                                visitante do site e mostrar anúncios relevantes em 
                                outros sites. Este cookie funciona identificando 
                                exclusivamente seu navegador e dispositivo.
                            </p>
                        </div>
                        
                        <div>
                            <h3>COOKIES NECESSÁRIOS</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cookies</th>
                                        <th>Nome</th>
                                        <th>Domínio</th>
                                        <th>Data de Expiração</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="4">GOOGLE</td>
                                    </tr>
                                    <tr>
                                        <td>_gid</td>
                                        <td>Google</td>
                                        <td>1 dia</td>
                                    </tr>
                                    <tr>
                                        <td>_ga</td>
                                        <td>Google</td>
                                        <td>2 anos</td>
                                    </tr>
                                    <tr>
                                        <td>_dc_gtm_UA-19358192-1</td>
                                        <td>Google</td>
                                        <td>1 dia</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <p>
                                <strong>
                                    _gid: 
                                </strong>
                                Associado ao Google Universal Analytics. 
                                Armazena e atualiza um valor único para cada 
                                página visitada.
                            </p>

                            <p>
                                <strong>
                                    _ga: 
                                </strong>
                                Associado ao Google Universal Analytics. Este 
                                cookie é usado para distinguir usuários únicos 
                                atribuindo um número gerado aleatoriamente como 
                                um identificador de cliente. Ele é incluído em 
                                cada solicitação de página em um site e usado 
                                para calcular dados de visitantes, sessões e 
                                campanhas para os relatórios de análise de sites.
                            </p>

                            <p>
                                <strong>
                                    _dc_gtm_UA-19358192-1: 
                                </strong>
                                Este cookie é associado a sites que usam o 
                                Gerenciador de tags do Google para carregar 
                                outros scripts e códigos em uma página. Onde 
                                é usado, pode ser considerado estritamente 
                                necessário, pois sem ele, outros scripts podem 
                                não funcionar corretamente. O final do nome é um
                                número exclusivo que também é um identificador 
                                para uma conta associada do Google Analytics.
                            </p>
                        </div>

                        <div>
                            <h3>
                                COMO POSSO VER QUAIS COOKIES ESTÃO INSTALADOS NO
                                MEU DISPOSITIVO E COMO POSSO REMOVÊ-LOS OU 
                                BLOQUEÁ-LOS?
                            </h3>
                            <p>
                                Se você quiser saber quais cookies estão 
                                instalados no seu dispositivo, ou se deseja 
                                excluí-los ou restringi-los, use a configuração 
                                do seu navegador. Você encontrará mais explicações 
                                sobre como proceder clicando nos links abaixo.
                            </p>
                        </div>

                        <div>
                            <p>
                                Para encontrar informações relacionadas a outros 
                                navegadores, visite o site do desenvolvedor do 
                                navegador.
                            </p>

                            <ul>
                                <li><a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox" target="_blank" rel="noopener noreferrer">Firefox</a></li>
                                <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR" target="_blank" rel="noopener noreferrer">Chrome</a></li>
                                <li><a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Safari</a></li>
                                <li><a href="https://support.microsoft.com/pt-br/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank" rel="noopener noreferrer">Internet Explorer</a></li>
                            </ul>

                            <p>
                                Lembre-se que o uso de cookies nos permite te 
                                oferecer uma melhor experiência em nossos Produtos 
                                e Serviços. Se você bloquear cookies em nossos 
                                Produtos, não podemos garantir o correto funcionamento 
                                de todas as funcionalidades e talvez você não 
                                consiga acessar determinadas áreas de nossos 
                                Produtos. Além disso, é bem provável que certas 
                                funções e páginas não funcionem da maneira usual.
                            </p>
                        </div>

                        <div>
                            <h3>ALTERAÇÃO NA POLÍTICA DE COOKIES</h3>
                            <p>
                                Como estamos sempre buscando melhorar nossos 
                                Produtos, essa Política de Cookies pode passar 
                                por atualizações. Dessa forma, recomendamos visitar 
                                periodicamente esta página para que você tenha 
                                conhecimento sobre as modificações. Caso sejam 
                                feitas alterações relevantes que ensejam um novo 
                                consentimento seu, iremos publicar essa atualização 
                                e solicitaremos um novo consentimento seu.
                            </p>
                        </div>

                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <button
                        type="button"
                        class="btn-green"
                        @click="close"
                        >
                            Fechar
                        </button>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'modal',

        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
 
    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .2s ease
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 0px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        max-width: 500px;
        max-height: 90%;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        border-bottom: 1px solid #eeeeee;
        justify-content: space-between;
        align-items: center;
        img {
            width: 20%;
        }
        h6 {
            font-size: 16px;
            color: $light-gray;
        }
    }

    .modal-footer {
        border-top: 1px solid #eeeeee;
        justify-content: flex-end;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
        color: #000;
        margin: 0 32px;
        div {
            margin-bottom: 64px;
            overflow-x: auto;
            h3 {
                margin-bottom: 10px;
            }

            p {
                margin: 10px 0;
            }

            ul {
                list-style-type: none;
                li {
                    margin: 10px 0;
                    a {
                        text-decoration: none;
                        color: $light-blue;
                    }
                }
            }
        }

        table {
            border-collapse: collapse;
            tr {
                th {
                    border: 1px solid #ddd;
                    padding: 8px;
                }
                td{
                    border: 1px solid #ddd;
                    padding: 8px;
                }

                &:nth-child(even) {
                    background-color: #f2f2f2;
                }
            }
        }
    }

    .btn-close {
        border: none;
        font-size: 20px;
        padding: 20px;
        cursor: pointer;
        font-weight: bold;
        color: $light-gray;
        background: transparent;
    }

    .btn-green {
        color: white;
        background: $light-blue;
        border: 1px solid $light-blue;
        border-radius: 6px;
        padding: 16px 32px;
        font-size: 14px;
        font-family: $museo500;
    }
</style>