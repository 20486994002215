<template>
    <footer>
        <div class="footer">
            <hr>
            <div class="info">
                <div class="image">
                    <img src="@/assets/images/oston-footer.svg" alt="Logo Oston">
                </div>
                <div class="links">
                  <div class="right">
                        <ul>
                            <li class="button" @click="openPolicyOfCookes">Política de Cookies</li>
                            <li class="button" @click="openPrivacyOfPolicy">Política de Privacidade</li>
                        </ul>
                    </div>
                </div>
                <div class="address">
                    <img src="@/assets/images/location-pin.svg" alt="Pin">
                    <p>
                        <strong>Porto Alegre, RS - Brasil</strong><br />
                        <strong>Rio de Janeiro, RJ - Brasil</strong><br />
                        <strong>São Paulo, SP - Brasil</strong><br />
                    </p>
                </div>
            </div>
        </div>
        <Modal v-show="isModalVisible" @close="closeModal" />
    </footer>
</template>

<script>
import Modal from "../components/Modal"

export default {
    name: 'Footer',
    components: {
        Modal
    },
    data () {
        return {
            isModalVisible: false,
            policyOfCookiesUrl: 'https://legal.oston.io/politica-de-cookies',
            privacyOfPolicyUrl: 'https://legal.oston.io/politica-de-privacidade',
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
            fullpage_api.setAutoScrolling(false);

            document.body.style.overflow= 'hidden';

        },
        closeModal() {
            this.isModalVisible = false;
            fullpage_api.setAutoScrolling(true);
             document.body.style.overflow= 'visible';
        },
         openPolicyOfCookes() {
            window.open(this.policyOfCookiesUrl, '_blank');
        },
        openPrivacyOfPolicy() {
            window.open(this.privacyOfPolicyUrl, '_blank');
        }
    },
}
</script>

<style lang="scss" scoped>
footer {
  // height: 500px;
  // position: relative;
  color: #fff;

  .footer {
    // position: absolute;
    bottom: 0;
    width: 100%;

    .image {
      img {
        width: 74px;
        height: 20px;
      }
    }

    .info{
        display: flex;
        justify-content: space-around;
        padding: 27px 0 0 0;

      .links {
          display: flex;
          font-family: $museo700;
          font-size: 14px;
          width: 300px;
          justify-content: space-around;
          ul {
            list-style-type: none;
            li {
              line-height: 32px;
              color: $light-blue;
            }
            .button {
              cursor: pointer;
            }
          }
      }

      .address {
          display: flex;
          align-items: flex-start;
          color: $light-blue;
          strong {
              font-family: $museo700;
          }
          p {
              margin-left: 10px;
          }
      }
    }
  }
}

/* iPhone 5 */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 374px)
  and (-webkit-min-device-pixel-ratio: 2) {
    footer {
        .footer {
            .info {
                padding: 10px;
                .links {
                  width: 33%;
                }
                .address {
                    
                    p {
                        margin-left: 5px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
  }

/* Portrait iPhone 6*/
@media only screen 
  and (min-device-width: 275px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    footer {
        .footer {
            .info {
                padding: 12px 10px;
                .links {
                  width: 33%;
                }
                .address {
                    font-size: 13px;
                    p {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
</style>
