<template>
    <div id="burger"
         :class="{ 'active' : active }"
         @click.prevent="$parent.toggle">
        <slot>
            <button type="button" class="burger-button" title="Menu">
                <span class="burger-bar burger-bar--1" :style="styleObj"></span>
                <span class="burger-bar burger-bar--2" :style="styleObj"></span>
                <span class="burger-bar burger-bar--3" :style="styleObj"></span>
            </button>
        </slot>
        <MenuItems :active="active" :isTelecom="isTelecom"/>
    </div>
</template>

<script>
    import MenuItems from "./MenuItems"
    export default {
        name: 'BurgerMenu',
        props: {
            active: {
              type: Boolean,
              required: false,
              default: false,
            },
            isTelecom: {
              type: Boolean,
              required: true,
              default: false
            }
        },
        components: {
            MenuItems
        },
        computed: {
          styleObj() {
            if (this.isTelecom) {
              return {
                'background-color': '#000',
              };
            } else {
              return {
                'background-color': '#fff',
              };
            }
          }
        },
        methods: {
          goto(ref) {
            this.$parent.goto(ref);
          }
        }
    }
</script>

<style>
   .hidden {
        visibility: hidden;
    }

    button {
        cursor: pointer;
    }

    /* remove blue outline */
    button:focus {
        outline: 0;
        float: right;
    }

    .burger-button {
        position: relative;
        height: 30px;
        width: 32px;
        display: block;
        z-index: 999;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        pointer-events: all;
        transition: transform .6s cubic-bezier(.165,.84,.44,1);
    }

    .burger-bar {
        background-color: #fff;
        position: absolute;
        top: 50%;
        right: 6px;
        left: 6px;
        height: 2px;
        width: auto;
        margin-top: -1px;
        transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
    }

    .burger-bar--1 {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    .burger-bar--2 {
        transform-origin: 100% 50%;
        transform: scaleX(.8);
    }

    .burger-button:hover .burger-bar--2 {
        transform: scaleX(1);
    }

    .no-touchevents .burger-bar--2:hover {
        transform: scaleX(1);
    }

    .burger-bar--3 {
        transform: translateY(6px);
    }

    #burger.active .burger-button {
        transform: rotate(-180deg);
        float: right;
    }

    #burger.active .burger-bar {
        background-color: #fff;
    }

    #burger.active .burger-bar--1 {
        transform: rotate(45deg)
    }

    #burger.active .burger-bar--2 {
        opacity: 0;
    }

    #burger.active .burger-bar--3 {
        transform: rotate(-45deg)
    }

</style>